import React from 'react'
import ReactDOM from 'react-dom'
import AppContainer from 'containers/AppContainer'
import * as serviceWorker from 'serviceWorker'
import { createBrowserHistory } from 'history'
import { stopReportingRuntimeErrors } from 'react-error-overlay'
import './index.scss'
import ReactGA from 'react-ga'

const TRACKING_ID = 'G-7H1F9Y0BX1'
ReactGA.initialize(TRACKING_ID)
if (process.env.NODE_ENV === 'development') {
  stopReportingRuntimeErrors() 
}

const history = createBrowserHistory()

ReactDOM.render(
 <React.StrictMode>
    <AppContainer history={history} />,
   </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
